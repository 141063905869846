define("discourse/plugins/discourse-solved/discourse/components/solved-unaccept-answer-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.unacceptAnswer = unacceptAnswer;
  function unacceptAnswer(post, appEvents) {
    // TODO (glimmer-post-menu): Remove this exported function and move the code into the button action after the widget code is removed
    unacceptPost(post);
    appEvents.trigger("discourse-solved:solution-toggled", post);
    post.get("topic.postStream.posts").forEach(p => {
      p.set("topic_accepted_answer", false);
      appEvents.trigger("post-stream:refresh", {
        id: p.id
      });
    });
  }
  function unacceptPost(post) {
    if (!post.can_unaccept_answer) {
      return;
    }
    const topic = post.topic;
    post.setProperties({
      can_accept_answer: true,
      can_unaccept_answer: false,
      accepted_answer: false
    });
    topic.set("accepted_answer", undefined);
    (0, _ajax.ajax)("/solution/unaccept", {
      type: "POST",
      data: {
        id: post.id
      }
    }).catch(_ajaxError.popupAjaxError);
  }
  class SolvedUnacceptAnswerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    unacceptAnswer() {
      unacceptAnswer(this.args.post, this.appEvents);
    }
    static #_2 = (() => dt7948.n(this.prototype, "unacceptAnswer", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="extra-buttons">
          {{#if @post.can_unaccept_answer}}
            <DButton
              class="post-action-menu__solved-accepted accepted fade-out"
              ...attributes
              @action={{this.unacceptAnswer}}
              @icon="check-square"
              @label="solved.solution"
              @title="solved.unaccept_answer"
            />
          {{else}}
            <span
              class="accepted-text"
              title={{i18n "solved.accepted_description"}}
            >
              <span>{{icon "check"}}</span>
              <span class="accepted-label">
                {{i18n "solved.solution"}}
              </span>
            </span>
          {{/if}}
        </span>
      
    */
    {
      "id": "A98Uepem",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"extra-buttons\"],[12],[1,\"\\n\"],[41,[30,1,[\"can_unaccept_answer\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"post-action-menu__solved-accepted accepted fade-out\"],[17,2]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"unacceptAnswer\"]],\"check-square\",\"solved.solution\",\"solved.unaccept_answer\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"accepted-text\"],[15,\"title\",[28,[32,1],[\"solved.accepted_description\"],null]],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,2],[\"check\"],null]],[13],[1,\"\\n          \"],[10,1],[14,0,\"accepted-label\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"solved.solution\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@post\",\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-solved/discourse/components/solved-unaccept-answer-button.js",
      "scope": () => [_dButton.default, _discourseI18n.i18n, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SolvedUnacceptAnswerButton;
});